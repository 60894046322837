
input[type="checkbox"]{
	appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    width: 35px;
    height: 35px;
    border: 1px solid #ddd !important;
    border-radius: 5px;
    cursor: pointer;
    content: "";
    padding: 5px;
    position: relative;
    &:checked{
		content: "";
	    border-color: #ff3651 !important;
	    padding: 5px;
	    position: relative;

    	&::after{
			content: "";
		    width: 10px;
		    height: 20px;
		    position: absolute;
		    left: 25%;
		    top:50%;
		    border-radius: 0;
		    border-bottom: 3px solid #ff3651;
		    border-right: 3px solid #ff3651;
		    transform: rotate(45deg) translate(-50%, -60%);
    	}
    }
	&:disabled{
		opacity: 0.4;
	}
}
.form-check{
	margin: unset;
	input[type='checkbox']{
		position: absolute;
		z-index: -999;
		opacity: 0;
		&+label{
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 0;
			width: unset;
			.checkbox{
				width: 24px;
				height: 24px;
				border-radius: 4px;
				background-color: #f0f0f0;
				border: 1px solid #d0d0d0;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s ease;
				color: #ffffff;
				i{
					opacity: 0;
				}
			}
		}
		&:checked{
			&+label{
				.checkbox{
					background-color: #ff0009;
					border-color: #ff0009;
					i{
						opacity: 1;
					}
				}
			}
		}
	}
}