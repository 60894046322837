.dashboard-icon{
	width: 52px;
	height: 52px;
	border-radius: 52px;
	background-color: $red-rgba;
	display: flex;
	align-items: center;
	justify-content: center;
	img{
		max-height: 30px;
		max-width: 30px;
	}
}
.avatar{
	&-item{
		&-wrap{
			display: flex;
			flex-wrap: wrap;
		}		
		&-content{
			display: flex;
			flex-wrap: wrap;
		}
	}
}