.ReactModal__Overlay {
    z-index: 9999;
    .custom-modal{
        margin: 0 auto !important;
        max-width: 80%;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        overflow: visible;
        width: 100%;
        padding: 40px;
        @media(min-width:1400px){
            max-width: 60%;
        }
        @media(max-width:767px){
            padding: 25px;
        }
        @media (max-width: 575px){
            overflow-y: clip;
        }
        @media(max-width:420px){
            padding: 20px;

        }
    }
}
.modal{
    &_header{
        @media (max-width: 575px){
        position: absolute;
        left: 0;
        right: 0;
        padding: 5px 20px;
        background: #fff;
        top: 20px;
        height: 50px;
        }
    }
    &_body{
        padding-top: 25px;
        @media (max-width: 575px){
        padding-top: 75px;
        overflow-y: auto;
        overflow-x: clip;
        max-height: 400px;
        padding-bottom: 40px;
        }
    }
    &-title{
        font-size: 24px;
        font-weight: 600;
        @media (max-width: 767px){
            font-size: 20px;
        }
        @media (max-width: 420px){
            font-size: 18px;

        }
    }
}