.progress{
    &-bar{
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        height: 4px;
        background-color: lighten($red-rgba, 40) ;
    }
    &-fill{
        height: 100%;
        background-color: $red-rgba ;
    }
}