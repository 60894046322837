.navbar{
	.navbar-brand-wrapper{
		.navbar-brand {
			img{
				height: auto;
				width: 130px;
			}
			&.brand-logo-mini{
				img{
					width: 100%;
				    max-width: 40px;
				    height: 40px;
				    margin: auto;
				    border-radius: 100px;
				    border: 1px solid rgb(0 0 0 / 20%);
				    object-fit: contain;
				}
			}
		}
	}
}

.mdi-account-circle:before {
    content: "\F009";
    color: #000;
    font-size: 28px;
}