.form-radio{
	position: relative;
	&-group{
		display: flex;
		flex-wrap: wrap;
		& > *{
			margin-right: 30px;
			@media (max-width: 767px){
				margin-right: 15px;
				margin-bottom:15px;

			}
			&:last-child{
				margin-right: 0;
			}
		}
	}
	input[type='radio']{
		position: absolute;
		z-index: -999;
		opacity: 0;
		&+label{
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 0;
			.radio{
				width: 24px;
				height: 24px;
				border-radius: 16px;
				background-color: #e0e0e0;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.3s ease;
				margin-right: 10px;
				@media (max-width: 767px){
					margin-right: 6px;
				}

				&::before{
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border-radius: 10px;
					background-color: #ffe5e6;
					opacity: 0;
				}
			}
		}
		&:checked{
			&+label{
				.radio{
					background-color: #ff0009;
					&::before{
						opacity: 1;
					}
				}
			}
		}
	}
	&-box{
		input[type='radio']{
			&+label{
				padding: 10px 15px;
				border-radius: 6px;
				background-color: #f0f0f0;
				border: 1px solid #d0d0d0;
				@media( max-width: 767px){
					padding: 8px 10px;

				}
				.radio{
					width: 24px;
					height: 24px;
					border-radius: 16px;
					background-color: rgba(#ffffff, 0.35) !important;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all 0.3s ease;
					margin-right: -24px;
					color: #ffffff;
					opacity: 0;
					@media (max-width: 767px){
						width: 20px;
						height: 20px;
						margin-right: -20px;
					}
					@media (max-width: 340px){
						display: none;
					}
					&::before{
						display: none;
					}
				}
			}
			&:checked{
				&+label{
					background-color: #ff0009;
					border-color: #ff0009;
					color: #ffffff;

					.radio{
						opacity: 1;
						margin-right: 10px;
						@media (max-width: 767px){
							margin-right: 6px;
						}
					}
				}
			}
		}
	}
}