.added{
	&-card{
		border: 1px solid rgba(0, 0, 0, 0.12);
		padding: 0px 12px 0px 22px;
		border-radius: 8px;
		margin-bottom: 28px;
         @media (max-width:1199px){
            padding: 0px 12px 0px 12px;
        }
        @media (max-width: 575px){
            padding: 0px 12px 0px 12px;

        }
    	.row{
    		margin-left: -12px;
    		margin-right: -12px;
    		[class^="col"]{
    			padding-left: 12px;
    			padding-right: 12px;
    			padding-top: 16px;
    			padding-bottom: 16px;
                @media (max-width: 575px){
                padding-bottom: 0px;

                }
    		}
    	}
    	.form{
    		&-group{
    			margin-bottom: 0px;
                .form__control{   
                    font-size: 14px;
                    border-color: rgba(0,0,0,0.12);
                    &.form__control--is-focused{
                        box-shadow: none;
                    }
                    &:hover{
                        background-color:rgba(255, 22, 53, 0.05);
                        border-color: rgba(255, 22, 53, 0.25);
                        .form__indicator-separator{
                            background-color:rgba(255, 22, 53, 0.25);
                        }
                    }
                    .form__indicator{
                        color: #000000;
                        &-separator{
                            background-color: rgba(0,0,0,0.12);
                        }
                    }
                }
                .form-control{
                    height: 38px;
                    padding:4px 10px;
                }
                .css-b62m3t-container{
                    .form__menu *{
                        background-color: #fff;
                        color: #000000;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }
    		}
    	}
    	.row{
    	   .col{
    		&-delete{
    			display: flex;
    			align-items: center;
    			justify-content: center;
    			background-color: rgba(255, 22, 53, 0.05);
    			border-left: 1px solid rgba(0, 0, 0, 0.08);
    			border-radius: 0px 8px 8px 0;
                  @media (max-width: 1199px){
                    border: none;
                    border-radius: 0px;
                  }
                @media (max-width: 575px){
                    border-radius: 0px;
                    border: none;
                    padding-bottom: 16px;

                    margin-top: 16px;
                }
    		}
    	   }	
    	}

	}
}
