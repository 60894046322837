.card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    &-header {
        padding: 15px 25px;
        background-color: transparent;
        .card-title{
            font-size: 20px;
            font-weight: 600;
            color: #000000;
            @media (max-width: 420px) {
                margin-bottom: 10px !important;
            }
        }
        
        @media(max-width: 767px) {
            padding: 15px 25px;

        }
        @media(max-width: 420px) {
            padding: 12px 20px;

        }

        &.card-header-custom {
            padding: 20px 30px;
        }
    }

    & &-body {
        padding: 20px 25px;
        header{
            padding: 0px;
            min-height: unset;
        }
        @media(max-width: 767px) {
            padding: 20px 25px;
        }
    }

    

    &-filter {
        padding: 14px 20px;
        margin-bottom: 20px;

        h4 {
            font-size: 24px;
            font-weight: 600;
            color: #000000;
            margin: 0;

            @media(max-width: 767px) {
                margin-bottom: 10px;

            }
        }

        &-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media(max-width: 767px) {
                flex-wrap: wrap;
                flex-direction: column;

            }
        }

        &-input {
            display: flex;
            gap: 20px;

            @media(max-width: 767px) {
                flex-wrap: wrap;
                flex-direction: column;
                flex: 1 0;
                width: 100%;

            }

            .input-date {
                flex: 1 0;

                input,
                select {
                    font-size: 14px;
                    font-weight: 600;
                    color: #000000;
                    min-height: 40px;
                    height: 40px;
                    border-radius: 8px !important;
                    border: 1px solid rgba(0, 0, 0, 0.20) !important;
                    background-color: #f5f5f5;
                    padding-top: 0;
                    padding-bottom: 0;

                    &:focus {
                        box-shadow: none;
                    }
                }

                input {
                    line-height: 40px;
                }
            }
        }

    }

    &-title {}

    &-body {
        &.card-body-custom {
            padding: 20px 30px !important;
        }
    }

    &.card-mini {
        margin-bottom: 25px;

        @media(max-width: 1550px) {

            .icon {
                &-md {
                    width: 40px !important;
                    height: 40px !important;
                }
            }

            .card-body {
                padding: 15px 10px !important;
            }
        }

        @media(max-width: 1500px) and (min-width: 1200px) {

            .icon {
                &-md {
                    width: 35px !important;
                    height: 35px !important;
                }
            }

            h4 {
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 70px;
            }

            .sidebar-icon-only {
                .icon {
                    &-md {
                        width: 46px !important;
                        height: 46px !important;
                    }
                }

                h4 {
                    max-width: 100%
                }
            }
        }
    }

    &.card-red {
        background-color: $red-rgba;
        box-shadow: none;
        margin-bottom: 20px;
        position: relative;

        .card {
            &-header {
                padding: 50px 20px 0px;
                border: none;
                display: flex;
                position: relative;
                background-color: transparent;

                @media(max-width: 1599px) {
                    padding: 41px 20px 0px;
                }

                @media(max-width: 1400px) {
                    padding: 20px 15px 0px;

                }
            }

            &-red {
                &-gradient {
                    position: absolute;
                    top: 0;
                    left: 0;
                    pointer-events: none;

                    img {
                        width: 100%;
                    }
                }
            }

            &-title {
                color: $white;
                font-size: 28px;
                font-weight: 600;

                @media(max-width: 1799px) {
                    font-size: 24px;
                }

                @media(max-width: 1599px) {
                    font-size: 20px;
                }

                @media(max-width: 1400px) {
                    font-size: 14px;

                }
            }

            &-image {
                background: #fff;
                border-radius: 100%;
                height: 70px;
                width: 70px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 20px;
                top: 20px;

                @media(max-width: 1799px) {
                    height: 60px;
                    width: 60px;
                }

                @media(max-width: 1599px) {
                    height: 45px;
                    width: 45px;
                }

                @media(max-width: 1400px) {
                    width: 30px;
                    height: 30px;

                }

                svg {
                    width: 32px;
                    fill: $red-rgba;

                    @media(max-width: 1799px) {
                        width: 30px;
                        height: 32px;
                    }

                    @media(max-width: 1599px) {
                        width: 24px;
                        height: 26px;
                    }

                    @media(max-width: 1599px) {
                        width: 18px;
                        height: 18px;

                    }
                }
            }

            &-body {
                padding: 10px 20px 15px;

                @media (max-width: 1400px) {
                    padding: 10px 15px 20px;
                }
            }

            &-list {
                color: #fff;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 8px;

                @media (max-width: 1400px) {
                    font-size: 13px;
                }

                &-text {
                    width: 48%;
                }

                &-value {
                    width: 48%;
                    word-break: break-all;
                    display: flex;
                    justify-content: flex-end;
                }

                &-dot {
                    padding: 0 5px;
                }
            }
        }

        &-height {
            min-height: calc(100% - 20px);
        }
    }

    .loader {
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70px;
        }
    }
}