$font-family: 'Montserrat', sans-serif;

$white: #ffffff;
$white-rgba: 255, 255, 255;

$black: #000;
$black-rgba: 0, 0, 0;
$dark-grey: #9c9fa6;

$light: rgba(255, 22, 53, 0.05);
$light-rgba: rgba(255, 22, 53, 0.05);

$red-rgba:#FF1635;

$yellow-rgba:#f7931a;

$navy-rgba: #2db6f5;

$blue-rgba:#757fef;
$text-grey:#808080;

$color:(
		red:$red-rgba,
		black:$black,
		dark-grey:$dark-grey,
		White:$white,
    grey: $text-grey,
	);

$bgcolor:(
	black: $black,
	white: $white,
  light:rgba(255, 22, 53, 0.20),
  dark: rgb(0, 0, 0, 0.05),
  red: $red-rgba,
);

$icontextcolor: (
  darkgrey:$dark-grey,
  white:$white,
  red: $red-rgba,
  yellow:$yellow-rgba,
  navy: $navy-rgba,
  blue: $blue-rgba,
  light: $light-rgba
);

$laoutbg:#F4F4F4;


$iconbgcolor: (
  red: $red-rgba,
  yellow:$yellow-rgba,
  navy: $navy-rgba,
  blue: $blue-rgba,
  light: $light-rgba
);




$fontweight: (
	100: 100,
	200: 200,
	300: 300,
	400: 400,
	500: 500,
	600: 600,
	700: 700,
);

$bodytext: (
	12: 12px,
	14: 14px,
	16: 16px,  
	18: 18px,  
	20: 20px,  
	22: 22px,  
	24: 24px,  
	26: 26px,  
	28: 28px,  
	30: 30px,  
	32: 32px,  
);

$headings: (
	h1: 64px,
	h2: 30px,
	h3: 24px,
	h4: 22px,
	h5: 18px,
	h6: 14px,
);
$round: (
	5: 5px,
	10: 10px,
	15: 15px,
	25: 25px,
	50: 50px,
	100: 100px,
	half: 50%,
	full: 100%,
);
$icon-btn: (
 	xl: 58px,
 	lg: 52px,
 	md: 46px,
 	sm-medium:37px,
 	sm: 30px,
 	xs: 24px,
 	xxs: 20px,
);

$height: (
  20: 20px,
  25: 25px,
  30: 30px,
  35: 35px,
  40: 40px,
);

$minwidth: (
 	100:100px,
 	150: 150px,
 	200: 200px,
 	250: 250px,
 	300: 300px,
);

$padding: (
  3: 3px,
 	5: 5px,
 	10: 10px,
  12:12px,
 	15: 15px,
 	20: 20px,
 	25: 25px,
 	30: 30px,
);

$borderwidth: (
	0: 0px,
 	1: 1px,
 	2: 2px,
 	3: 3px,
 	4: 4px,
 	5: 5px,
);

$bordercolor: (
 		light:rgba(255, 22, 53, 0.25),
 		dark: rgb(0, 0, 0, 0.20),
 		red: #FF1635,
 		black:#000000,
 	);

$cursor: (
	auto:auto,
	default: default,
	pointer: pointer,
	wait: wait,
	text: text,
	move: move,
	help: help,
	not-allowed	:not-allowed,
	);


$input:(
  xl: ( padding: 18px , fontSize: 22px ),
 	lg: ( padding: 16px , fontSize: 20px ),
 	md: ( padding: 14px , fontSize: 18px ),
 	sm: ( padding: 12px , fontSize: 16px ),
 	xs: ( padding: 10px , fontSize: 16px ),
 	xs-small:(padding:7px, fontSize:14px),
 	xs-sm:(padding:5.5px, fontSize:14px),
 	xxs:( padding: 4px  , fontSize: 14px ),
);

$btn: (
 	xl: ( padding: 18px , fontSize: 22px ),
 	lg: ( padding: 16px , fontSize: 20px ),
 	md: ( padding: 14px , fontSize: 18px ),
 	sm: ( padding: 12px , fontSize: 16px ),
 	xs: ( padding: 10px , fontSize: 16px ),
 	xxs:( padding: 4px  , fontSize: 14px ),
);

// padding
@each $name, $value in $padding {
  .padding-#{$name} {
  	 padding: $value !important;
  }
}
@each $name, $value in $padding {
  .paddingbottom-#{$name} {
   	padding-bottom: $value !important;
  }
}
@each $name, $value in $padding {
  .paddingleft-#{$name} {
   	padding-left: $value !important;
  }
}
@each $name, $value in $padding {
  .paddingright-#{$name} {
   	padding-right: $value !important;
  }
}
@each $name, $value in $padding {
  .paddingtop-#{$name} {
   	padding-top: $value !important;
  }
}

// margin
@each $name, $value in $padding {
  .margin-#{$name} {
  	 margin: $value !important;
  }
}
@each $name, $value in $padding {
  .marginbottom-#{$name} {
   	margin-bottom: $value !important;
  }
}
@each $name, $value in $padding {
  .marginleft-#{$name} {
   	margin-left: $value !important;
  }
}
@each $name, $value in $padding {
  .marginright-#{$name} {
   	margin-right: $value !important;
  }
}
@each $name, $value in $padding {
  .margintop-#{$name} {
   	margin-top: $value !important;
  }
}


// Min Widht
@each $name, $value in $minwidth {
  .minwidth-#{$name} {
   	min-width: $value;
  }
}
@each $name, $value in $height {
  .minheight-#{$name} {
    min-height: $value;
  }
}
@each $name, $value in $height {
  .maxheight-#{$name} {
    max-height: $value;
  }
}

@each $name, $value in $borderwidth {
  .borderwidth-#{$name} {
   	border-width: $value !important;
   	border-style: solid !important;
  }
}
@each $name, $value in $bordercolor  {
  .bordercolor-#{$name} {
   	border-color: $value !important;
  }
}


@each $name, $value in $bgcolor  {
  .bg-#{$name} {
   	background-color: $value !important;
  }
}


@each $name, $value in $icon-btn  {
  .icon-#{$name} {
   	width:$value;
   	height: $value !important;
  }
}

@each $name, $value in $iconbgcolor {
  .icon-#{$name} {
    background-color:$value;
    justify-content: center;
  }
}
@each $name, $value in $color {
  .text-#{$name} {
   color:$value;
  }
}
@each $name, $value in $icontextcolor {
  .icontext-#{$name} {
   color: $value;
   padding: 0px;
   display: flex;
   justify-content: center;
   &:hover{
   	color: $black;
   }
  }
}

@each $name, $value in $round  {
  .round-#{$name} {
   	border-radius: $value !important;
  }
}

@each $name, $value in $color {
  .btn-#{$name} {
  	 background-color: $value;
  }
}

@each $name, $value in $bodytext  {
  .bodytext-#{$name} {
   	font-size: $value !important;
   	line-height: $value + 10px !important;
	display: flex;
	align-items: center;
  }
}
@each $name, $value in $headings  {
  .heading-#{$name} {
   	font-size: $value;
   	line-height: $value + 10px;
  }
}
@each $name, $value in $fontweight  {
  .fontweight-#{$name} {
   	font-weight: $value;
  }
}
@each $name, $value in $cursor  {
  .cursor-#{$name} {
   	cursor: $value;
  }
}
@each $name, $value in $padding  {
  .gap-#{$name} {
    gap: $value;
  }
}





