
.content-wrapper{
	&.auth{
		display: flex;
		align-items: center;	
	    height: 100%;
	    width: 100%;
	    margin: 0 !important;
	    min-height: 100vh;
	    padding: 0;
	    .auth-content{
			height: 100vh;
			display: flex;
			width: 100%;
			.left-img{
				height: 100%;
			    width: 50%;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    padding: 30px 15px;
			    position: relative;
			    z-index: 1;
			    background: url('../../assets/images/loginbg.jpg');
			    background-position:center;
			    background-size: cover;
			    flex:0 50%;
			    @media(max-width: 991px){
			    	display: none;
			    }
			}
	    }
	    .auth-form-light{
	    	background: #ffffff;
	    	width: 50%;
    		display: flex;
    		flex-wrap: wrap;
		    align-items: center;
		    justify-content: center;
		    overflow-y: scroll;
		    padding: 50px;
		    text-align: left;
			@media (max-width: 1400px){
		    	padding: 25px;
			}
			@media (max-width: 991px){
				width: 100%;

			}
			@media (max-width: 767px){
				width: 100%;
		    	padding: 20px;
			}

		    .auth-form-content{
			    width: 100%;
			    max-width: 600px;
			    background: #fff;
			    padding: 50px;
			    border-radius: 25px;
			    // box-shadow: rgb(17 17 26 / 0%) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;

			    @media (max-width: 1400px){
				    max-width: 480px;
			    	padding: 25px;
			    }
			    @media (max-width: 767px){
			    	padding: 0px;

			    }
			    h4{
					color: #7B7B7B;
				    margin-bottom: 0;
				    font-weight: 400;
				    font-size: 20px;
				    line-height: 28px;
					span{
						font-weight: 600;
					}
			    	@media (max-width: 767px){
					    font-size: 14px;
					    line-height: normal;

			    	}
    			}
		     	.brand-logo{
	    			margin-bottom: 30px;
	   		 	}
	   		 	h1{
				    font-weight: 600;
				    color: #000;
				    font-size: 50px;
				    line-height: 60px;
				    margin-bottom: 5px;

			    	@media (max-width: 767px){
					    font-size: 26px;
					    line-height: normal;

			    	}
	   		 	}
	   		 	.auth-link{
					font-size: 16px !important;
				    color: #575757 !important;
				    cursor: pointer;
				    &:hover{
				    	text-decoration: underline;
    					color: #000 !important;
				    }
	   		 	}
		    }

	    }
	}
}
.auth {
	.brand-logo{
		img{
			width: 100px;
		}
	}
	&-form{
		padding-top: 50px;
		@media( max-width: 1400px){
			padding-top: 35px;
		}
		@media( max-width: 767px){
			padding-top: 25px;
		}

	}
	form {
		.auth{
			&-form{
				&-btn{
					height: auto;
				}
			}
		}
	}
}