.ReactModal__Overlay{
	.custom-modal{
		&.invoice-billing{
			width: 100%;
			max-width: 302px;
			padding: 15px;
			border-radius: 10px !important;
			h2.heading-h4{
				font-size: 18px !important;
				font-weight: 600;
			}
			.icon-md{
				width: 32px;
				height: 32px !important;
				font-size: 16px !important;
				line-height: 28px !important;
			}
			.invoice{
			        display: flex;
				    flex-direction: column;
				    gap: 10px;
				    width: 100%;
				    max-width: 300px;
				    margin: 10px auto 0;
					.header{
					border-bottom: 1px dashed #ddd;
					text-align: center;
					padding: 0 0 10px;
					h1{
					    font-size: 16px;
					    margin-bottom: 5px;
					    font-weight: 600;
					}
				}
				
				.gst-details{
					padding: 5px 0;
					span{
						font-size: 12px;
						white-space: nowrap;
					}
				}
				.sgt-bill-details{
					display: flex;
				    flex-direction: row-reverse;
				    justify-content: space-between;
				    gap: 20px;
				    border-bottom: 1px dashed #ddd;
				    .bill_no-gst{
						display: flex;
					    flex-direction: column;
					    width: 100%;
					   	.details{
							border-bottom: 1px dashed #ddd;
							padding: 0 0 5px;
						}
				    }
				}
				.invoice-table{
					thead{
						background: #efefef;
						tr{
							th{
								font-size: 12px;
								font-weight: 600;
						    	padding: 8px 5px;
							}
						}
					}
					tbody{
						tr{
							td{
								font-size: 12px;
								font-weight: 400;
								padding-left: 5px;
    							padding-right: 5px;
							}
						}
					}
					tfoot{
						padding-bottom: 15px; 
						tr{
							td{
								font-size: 12px;
								font-weight: 400;
								padding-left: 5px;
    							padding-right: 5px;
							}
							th{
								font-size: 12px;
								font-weight: 600;
							}
						}
					}
				}
				p{
					font-size: 12px;
					font-weight: 400;
					line-height: normal;
					margin-bottom: 5px;
				}
				.details{
					p{
		width: 90px;
	    text-overflow: ellipsis;
	    overflow: hidden;
					}
				}
			}
			&::-webkit-scrollbar{
				width: 2px;
			}
			&::-webkit-scrollbar-track {
			  box-shadow: inset 0 0 2px grey; 
			  border-radius: 2px;
			}
			&::-webkit-scrollbar-thumb {
			  background: #ddd; 
			  border-radius: 2px;
			}
			::-webkit-scrollbar-thumb:hover {
			  background: #b30000; 
			}
			.print-btn{
			    font-size: 12px;
			    padding: 8px 20px !important;
			    min-height: auto;
			    border-radius: 0;
			}
		}
	}
}

