.rdt{
	&_TableHeadRow{

	background-color: #fcfcfc !important;
    color: rgba(0, 0, 0, 0.70) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
	}
	&_TableBody{
		.kUMlDB{
		 	color: rgba(0, 0, 0, 0.70) !important;
		    font-size: 14px !important;
		}
	}
}

.tab{
	&-btn{
		font-weight:500;

		@media(max-width: 767px){
			min-height: 40px;
			padding: 8px 12px;
		}
		@media(max-width: 475px){
			width: 100%;
		}
		&.active{
			background: #ff0009;
			border-color: #ff0009 !important;
			color: #fff;
		}
	}
}