.sidebar {
    border-top-right-radius: 30px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: calc(100% - 90px);
    padding-top: 25px;
    min-height: unset;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 260px;
    @media (max-width: 1400px){
    	width: 200px;

    }

    @media(max-width: 991px) {
        height: calc(100% - 70px);
    }

    @media(max-width: 767px) {
        left: auto;
        border-top-right-radius: 0px;
        border-top-left-radius: 30px;
        height: calc(100% - 70px);
    }

    .nav {
        .nav {
            &-item {
                padding: 0;

                &:hover {
                    background: none;
                }

                .nav-link {
                    gap: 10px;
                    padding: 12px 30px;
                    color: $text-grey !important;
                 	@media (max-width: 1400px){
                    	padding: 10px 15px;
				    }

                    &:hover,
                    &.active {
                        background-color: rgba(255, 22, 53, 0.05);
                        color: #FF1635 !important;

                        i.menu-icon,
                        svg {
                            color: #FF1635;
                        }

                        svg {
                            fill: #FF1635;

                        }
                    }

                    i {
                        &.menu-icon {
                            margin-left: 0;
                            font-size: 24px;
                            line-height: 24px;
                            color: #6c6c6c;

                            @media (max-width: 1400px){
                            	font-size: 18px;

	                        }
                        }
                    }

                    svg {
                        color: #6c6c6c;
                        fill: #6c6c6c;
                        width: 24px;
                        height: 24px;
                        @media (max-width: 1400px){
                        	width: 18px;
                        	height: 18px;

                        }
                    }

                    .menu-title {
                        font-size: 16px;
                        line-height: 26px;
                        font-weight: 500;
                        @media (max-width: 1400px){
                        	font-size: 12px;
                    	
                    	}
                    }

                    i.menu-arrow {
                        font-family: "Material Design Icons" !important;
                    }

                    // &-package {
                    //     svg {
                    //         width: 22px;
                    //         height: 22px;
                    //     }
                    // }
                }

                .collapse {
                    .nav {
                        &-item {
                            padding: 0 30px;

                            .nav-link {
                                font-weight: 500;
                                padding-left: 35px;

                                &:before {
                                    left: 5px;
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-profile {
            &-text {
                margin-left: 0 !important;

                span {
                    font-size: 18px;
                    font-family: 'Poppins', sans-serif;

                    &.text-small {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}



/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
    .sidebar-offcanvas {
        .sidebar-icon-only & {
            right: 0;
        }
    }
}