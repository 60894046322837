.row{ 
   --bs-gutter-x: 20px;
}
body{
   background: $laoutbg !important;
}
body *{
   font-family: $font-family !important;
}
.col, .col-6, .col-12{
   padding-top: inherit;
   padding-bottom: inherit;
}
.main-panel{
   padding-left: 260px;
   width: 100%;
   transition: all 0.25s ease;


   @media(max-width: 1400px){
   padding-left: 200px;

   }
   @media(max-width: 767px){
      padding-left: 0px;
   }
   .sidebar-icon-only &{
      padding-left: 70px;
      width: 100%;

   }
   .content-wrapper{
      background-color: $laoutbg !important;
      padding: 20px 40px;
      @media (max-width: 1400px){
         padding: 20px 30px;
      }
      @media (max-width: 767px){
         padding: 20px 20px;

      }
      @media (max-width: 420px){
         padding: 15px;

      }
   }
   .footer{
       background: $laoutbg !important;
   }
}

.sidebar-icon-only .sidebar .nav .nav-item .nav-link {
   .rigt-icns{
      display: none;
   }
}
.section{
   margin-bottom: 30px;
   
   @media(max-width: 767px){
      margin-bottom: 20px;
   }
   &-title{
      font-size: 20px;
      font-weight: 600;
      color: #000000;
      @media (max-width: 420px) {
          margin-bottom: 10px !important;
      }
   }
}