table{
  &.permission{
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;

    th,td{
      padding: 5px 16px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.7) !important;
      font-size: 14px !important;
    }
    thead 
    {
      th{
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #fcfcfc !important;
        color: rgba(0, 0, 0, 0.7) !important;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 16px;
      }
    }
    /*tr:nth-child(even){
      background-color: #f2f2f2;
    }*/
    th:first-child, td:first-child{
      position: sticky;
      left: 0;
      z-index: 2;
/*      background-color: #f2f2f2;*/
    }
    th:nth-child(2){
      position: sticky;
      left: 32px; /* Adjust based on the width of the first column */
      z-index: 2;
      background-color: #f2f2f2;
    }
    th:first-child{
      z-index: 3;
    }
  }
}
  