.table{
	&.table-package{
		td {
		  padding-bottom: 8px;
		  padding-top: 8px;
		  input[type=checkbox]{
			width: 25px;
			height: 25px;
		  }
		}
	  }
	&-no-scroll{
		overflow: unset;
	}
	tr{
		th{
			font-size:14px;
			font-weight:500 !important;
			color:#000;
			text-align: left !important;
			border:none;
			padding-right: 12px;
			padding-left: 0px;
			&:last-child{
				padding-right: 0;
			}
		}
		td{
			box-shadow: none;
			border:none;
			padding-right: 12px;
			padding-left: 0px;
			&:last-child{
				padding-right: 0;
			}
			
			&.td{
				&-xs{
					width: 60px;
				}
				&-sm{
					width: 90px;
				}
				&-md{
					width: 120px;
				}

			}
		}
	}
	&-data{
		&-search{
			border: 1px solid #dbdbdb;
			min-height: 40px;
			color: #000000;
			padding: 2px 15px;
			&:focus{
				border: 1px solid #dbdbdb;
			}
		}
	}
	&.table-custom{
		td{
			input{
				&[type="number"], &[type="hidden"]{
					padding: 9px 10px;
					font-weight: 400;
					color: #212529;
					height: auto;
					margin-top: 6px;
					border: 1px solid #cccccc !important;
				}
			}
			.service_gst_txt, .service_total_txt{
				padding: 9px 10px;
				font-weight: 400;
				color: #212529;
				height: auto;
				margin-top: 6px;
				border: 1px solid #cccccc !important;
				border-radius: 5px;
			}
			
		}
	}
	&.billing-data{
		.text-right{
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			gap: 10px;
			.button-primary{
				background-color: #ff1635;
				display: flex;
				align-items: center;
				justify-content: center;
				i{
					margin: 0;
				}
			}
			select{
				max-width: 250px;
				
			}
			input{
				padding: 5px 10px;
				border: 1px solid #afacac;
			}
		}
		
		}
	&.table-billing{
		width: 100%;
		background-color: rgba(255, 22, 53, 0.05);
		border: 1px solid rgba(0, 0, 0, 0.07);
		tr{
			th, td{
				padding: 20px 20px;
				font-size: 16px;
				color: #000000;
			}
			td{
				text-align: right;
				white-space: normal;
				.discount-amount{
					font-weight: 500;
					font-size: 15px;
					margin-top: 6px;
					text-align: left;
				}
				.special-discount-text{
					font-size: 12px;
					color: #000000;
					text-align: left;
				}
				.discount{
					&-wrap{
						position: relative;
						&-field{
							position: absolute;
							right: 1px;
							top: 1px;
							border-left: 1px solid rgba(0, 0, 0, 0.12);
							select{
								width: 60px;
								color: #000;
								font-weight: 600;
								border-width: 0px 0px 0px 0px !important;
								border-radius: 0px 6px 6px 0 !important;
								background-image: none;
								text-align: center;
								background-color: transparent;
							}
						}
						input{
							&.discount{
								padding-right: 70px;
								&:hover, &:focus{
									.discount-wrap-field{
						    			border-color: rgba(255, 22, 53, 0.25) !important;
									}
								}
							}
						}
						&:hover, &:focus{
							.discount-wrap-field{
						    	border-color: rgba(255, 22, 53, 0.25) !important;
							}
						}
					}
				}
			}
			th{
				font-weight: 600 !important;
			}
		}
		tbody{
			
			tr{
				border-color: rgba(0, 0, 0, 0.07);
				&:first-child{
					border-top:none;
				}
				&:last-child{
					border-bottom: none;
				}
				&.tax-include{
					.form-group{
						input[type="checkbox"]{
							background-color: #ffffff;
							&:checked{
								border-color: #ff3651 !important;
								&::after{
									border-bottom: 3px solid #ff3651;
									border-right:  3px solid #ff3651;
									left: 22%;
									top: 48%;
								}
							}
						}
					}
				}
			}
		}
	}
	&-billing-btn{
		margin-top: 25px;
	}
	&.table-billing-form{
		tr{
			td{
				.css-3w2yfm-ValueContainer {
					flex-wrap: nowrap;
				}
				.css-b62m3t-container {
					max-width: 150px;
					min-width: 150px;
					@media(min-width:1800px){
						max-width: 100%;
						min-width: 100%;
					}

				}
				input{
					max-width: 174px;
					@media(min-width:1800px){
						max-width: 100%;
						min-width: 100%;
					}
				}
				.percentage{
					width: 100%;
					.service_gst_txt, .service_total_txt{

						max-width: 174px;
						display: block;
						min-height: 38px;
						margin: 0;
						@media(min-width:1800px){
							max-width: 100%;
							min-width: 100%;
						}
					}
				}
				
				
			}
		}
	}
}

.erkbiK{
	div:first-child{
		white-space:nowrap !important;
	}
} 
.rdt_TableCell:last-child {
    min-width: 204px;
}
.rdt_TableCol:last-child {
    min-width: 204px !important;
}