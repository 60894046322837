@media screen and (min-width:1399px) {
    .card-red-height {
        .card-header{
            .card-title{
                font-size: 20px;
            }
        }
    }
    .card.card-red .card-header {
        padding: 41px 10px 20px !important;
    }
}

@media screen and (max-width:767px) {
    .bodytext-30{
        font-size: 18px !important;
        line-height: 20px !important;
        text-align: center;
    }
    div#package {
        width: 100%;
    }
    .table-billing-btn{
        margin-top: 10px !important;
    }
    .sidebar-icon-only {
        .main-panel{
            padding-left: 0px;
        }
    }
    
}
@media screen and (max-width:475px) {
    .navbar-nav{
        .form-group{
            width: 100%;
            select{
                max-width: 100% !important;
                min-width: unset !important;
                font-size: 12px !important;
                padding: 10px 12px !important;
            }
        }
    } 
    
}
@media screen and (min-width:992px) {
    .right-ico-res img{
        display: none;   
    }
}

.prod-list{
    td{
        width: 100%;
        padding: 5px 0px;
        :first-child {
            min-width: 130px !important;
        }
    }
    tfoot {
        border-top: 1px solid #e1dede;
        line-height: 12px;
        margin-top: 10px !important;
    }
} 

.custom-head-bg .nav-tabs{
    padding: 15px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    .nav-item{
            .active{
                background-color: red;
            }
    }
       
}
.mdi-menu {
    background-color: #fff;
    padding: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card{
    .button-primary {
        text-align: center;
    }
} 
.right-ico-res{
    background-color: #fff;
    a{
        background-color: #fff;
        border-radius: 2px !important;
    }
} 
.hMFeQo{
    display: flex;
    justify-content: start !important;
}

.hr-job-card {
    overflow-x: hidden;
    img{
        width: 80%;
        margin: auto 0;
    }
}

.check-upg{
    input {
        width: 13px;
        height: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        
        :checked:after{
            display: none !important;
        }
    }

} 
.check-upg input:checked:after {
    display: none !important;
}
.invoice-table {
    td {
        border-right: 1px dashed #e9e9e9;
    }
}

.custom-head-bg{
    .nav-item {
        button {
            color: #000;
        }
        .active {
            background-color: #FF1635 !important;
            color: #fff;
            border-radius: 8px;
        }
    }
} 
.sidebar{
    .nav-link{
        .rigt-icns{
            position: absolute;
            right: 15px;
            svg {
                font-size: 12px !important;
                height: 20px !important;
            }
        }
    }
}

