.btn{
	padding: 12px 25px;
	min-height: 46px;
	font-size: 15px;
	font-weight: 500;
	border-radius: 8px;


	@media (max-width: 767px){
		padding: 10px 15px;
		min-height: 42px;

	}
	@media (max-width: 420px){
		padding: 8px 12px;
		min-height: 38px;
		font-size: 14px;

	}
	.icon{
		font-size: 18px;
		i{
			font-size: inherit;
		}
		svg{
			width: 1em;
			height: 1em;
		}
	}
	&.btn{
		&-icon{
			width: 42px;
			height: 42px;
			min-height: 42px;
			&-left{
				.icon{
					margin-right: 5px;
				}
			}
			&-sm{
				@media(max-width: 575px){
					width: 36px;
					height: 36px;
					min-height: 36px;
					font-size: 20px;
				}
			}
		}
	}
	&-close{
		background-image:  none;
		background-color: #ebebeb;
		color: #343434;
		font-size: 24px;
		border-radius: 20px;
/*		@media (max-width: 575px ){
			position: absolute;
			top: 5px;
			right: 5px;
		}*/
		i{
			font-size: inherit;
		}
		
	}

	&-gray{
	}

	&-sm{

	}
	&-gradient{
		&-danger{
			background: linear-gradient(to right, #FF1635, #FF1635) !important;
		}
	}
	&-primary{
		background-color: #FF1635;
		border-color: #FF1635;
		color: #fff;
		line-height: 20px;
		
		&:hover, &:focus{
			border-color: darken(#FF1635, 20) !important;
			background-color: darken(#FF1635, 20) !important;
			color: #fff;
		}
	}
	&-plane{
		border: 1px solid rgba(0, 0, 0, 0.2) !important;
		background-color: #f5f5f5;
	}
	&-h-auto{
		min-height: auto;
	}

}
.button{
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	transition: all 0.25s ease;
	min-height: 50px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	@media(max-width: 1400px){
		min-height: 46px;
	}
	@media(max-width: 767px){
		min-height: 42px;
	}
	&-primary{
		background-color: #FF1635;
		color: #fff;
		
		&:hover{
			background-color: darken(#FF1635, 20);
			color: #fff;
		}
	}
	&-success{
		background-color: #198754;
		color: #fff;
		
		&:hover{
			background-color: darken(#198754, 20);
			color: #fff;
		}
	}
	&-error{
		background-color: #FF1635;
		color: #fff;
		
		&:hover{
			background-color: darken(#FF1635, 20);
			color: #fff;
		}
	}
	&-secondary{
		background-color: #808080;
		color: #fff;
		
		&:hover{
			background-color: darken(#808080, 20);
			color: #fff;
		}
	}
	&-nav{
		.button{
			margin-right: 20px;
			&:last-child{
				margin-right: 0px;
			}
		}
	}
	&-pill{
		min-height: 30px;
		border-radius: 50px;
		&-next{
			img{
				transform: rotateY(180deg);
			}
		}
		&-prev{

		}
	}
	&-icon{
		font-size: 18px ;
		cursor: pointer;
		border-radius: 5px;
		border: none ;
		width: 38px;
		height: 38px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		min-height: auto;

		&-left{
			display: inline-flex;
			align-items: center;

			i{
				margin-right: 10px;
				color: inherit;
			}
		}
		&-xl{
			width: 50px;
			height: 50px;
		}
	}
	&.btn-arrow{
		padding: 0;
		height: 30px;
		width: 50px;
		border-radius: 30px !important;
	}
}