.customer{
	&_detail{
		padding:0;
		margin-top: 10px;
		border-color: rgba(255, 22, 53, 0.25);
		background-color: rgba(255, 22, 53, 0.05);
		&_wrap{
			display: flex;
			justify-content: space-between;
			padding:14px 20px;
			p{
				color: #000;
				font-size: 15px;
				font-weight: 500;
			}
			[class^="cust_"]{
				font-weight: 700;
			}
		}
		&_header{
			padding:18px 20px;
			border-bottom: 1px solid rgba(255, 22, 53, 0.25);
			h5{
				margin:0;
			}
		}
		&_body{
			padding: 7px 0;
			border-radius: 10px;
			background-color: #ffffff;
			.customer_detail_wrap:last-child{
				border:none;
			}
		}
	}
}