.form-control{
	.form{
		&__control, &__control *{
			border: 0 !important;
		    padding: 0 !important;
		    background: none;
		    box-shadow:none !important;
		    @media (max-width: 1400px){
		    	min-height: auto !important;
		    }
		    @media (max-width: 767px){
			    max-width:95% !important;
		    }
		    &:hover, &:focus{
		    	background: none;
		    	border: none;
		    	outline: none;
		    	box-shadow:none;
		    }
		}
		&__indicators{
			display: none !important;
		}
	}
}