a{
	text-decoration:none !important;
}
.row{
	&.col-padding > *{
		padding-left:calc(var(--bs-gutter-x) * .25) !important;
		padding-right:calc(var(--bs-gutter-x) * .25) !important; 	
	}
}

.shadow-none{
	box-shadow: none !important;
}