.form{
	&-group{
		.text-danger {
			font-size: 14px;
		}
		label{
			font-size: 14px ;
			line-height: 20px ;
			color: #424242 ;
			display: inline-block;
			margin-bottom: 0.5rem;
			font-weight: 500;
			width: 100%;
		}
		.multiselect{
			padding: 0;
			.css-13cymwt-control{
				border: none;
				height: 100%;
				outline: none;
			}
		}
		textarea{
			&.form-control{
					background-color: none;
				&:focus{
					background-color: none;
				}
			}
			&.billing_remark{
				min-height: 150px;
			}
		}
		select{
			&.form{
				&-control{
					color: #000000;
				}
			}
		}
	}
	&-lable{
		color: #9c9fa6 !important;
	}
	&-control{
		border-radius: 6px !important;
		font-size: 14px !important;
		border: 1px solid rgba(0,0,0,0.12) !important;
		height: 50px;
		margin-bottom: 5px;
		&:focus,&:hover{
			background-color: rgba(255, 22, 53, 0.05);
			border-color: rgba(255, 22, 53, 0.25) !important;
		}
		@media(	max-width: 1400px){
			height: 46px;
			min-height: 46px;
			padding: 12px 25px;

		}
		@media(	max-width: 767px){
			height: 42px;
			min-height: 42px;
			padding: 10px 20px;

		}
	}
	&-select{
		margin-bottom: 0 !important;
		outline:none !important;
		padding-right: 35px;
		&.bg-danger-light{
			color: #000000;
			background-color: rgb(255 22 53 / 5%);
			outline: none !important;
			padding: 12px 24px;
			font-weight: 500;
			font-size: 14px !important;
		}
		&-wrap{

		
			.css-13cymwt-control,.css-t3ipsp-control{
	    		min-height: 50px;
	    		border-color: rgba(0, 0, 0, 0.12);
	    		border-radius: 6px;
	    		font-size: 14px;
	    	}
	    	.css-13cymwt-control:hover,.css-13cymwt-control:focus{
	    		border-color: rgba(255, 22, 53, 0.25);
	    		background-color: rgba(255, 22, 53, 0.05);
	    	}
	    	.css-t3ipsp-control,.css-t3ipsp-control:hover{
	    		box-shadow: none;
	    	}
	    	.css-t3ipsp-control:hover{
	            border-color: rgba(0, 0, 0, 0.12);
	    	}
	    	.css-1u9des2-indicatorSeparator{
	    		background-color: rgba(0, 0, 0, 0.12);
	    	}
	    	.form__indicator{
	    		svg{
	    			color: #000000;
	    		}
	    	}
	    	
	    	.css-1xc3v61-indicatorContainer,.css-15lsz6c-indicatorContainer{
	    		svg{
	    			color: black;
	    			width: 17px;
	    			height: 17px;
	    		}
	    	}
    	}
	}
	&-field{
		&-w{
			min-width: 200px;
		}
	}
}
select{
	&.form{
		&-control{
			color: #000000;
		}
	}
}