.navbar{
	.navbar{
		&-menu{
			&-wrapper{
				flex: 1 0;
				width: 0;
				padding: 0px 20px;
			}
		}
		&-brand{
			&-wrapper{
				width: 200px;
				@media(max-width: 991px){
					width: 55px;
				}
				@media(max-width: 991px){
					width: 75px;

				}
				@media(max-width: 480px){
					width: 55px;

				}
			}
		}
	}
}
.nav{
	&bar{
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		.navbar{
			&-brand{
				&-wrapper{
					.navbar{
						&-brand{
							&.brand-logo-mini{
								img{
									@media(max-width: 767px){
										width: auto;
									}
								}
								
							}
						}
					}
				}
			}
		}
	}
	.brand-logo{
	    text-align: left;
	}
	&-link{
		img{
			&.profile{
				width: 100%;
				height: 46px;
				object-fit: cover;
			}
		}
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.navbar{
		&-menu{
			&-wrapper{
				@media(max-width:991px){
					flex: 1 0;
					width: 0;
				}
				.navbar{
					&-nav{
						@media(max-width:991px){
							flex: 1 0;
							width: 0;
						}
					}
					
				}
			}
		}

	}
	.navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu-left.navbar-dropdown{
		right: 0px;
		left: auto;
		@media (max-width:991px){
			right: 40px;
		}
		@media (max-width:767px){
			right: 15px;
			left: 15px;
		}
		.dropdown-item{
			&:hover{
				color: unset;
				background-color: rgba(#FF1635, 0.05);
			}
		}
	}	
	.navbar-menu-wrapper .navbar-nav .nav-item.dropdown .dropdown-menu.notification-menu{
		max-width: 70%;
		width: 30rem;
		min-width: 30rem;
		max-height: 500px;
    	overflow-y: auto;
    	@media (max-width:767px){
			width: unset;
			max-width: unset;
			min-width: unset;
		}
    	h6{
    		font-size: 18px;
    		font-weight: 700;
    	}
		.dropdown-item{
			white-space: unset;
			margin:  unset;
			padding: 10px 16px;
			font-weight: 500;
			@media (max-width:767px){
				flex-wrap: wrap;
				flex-direction: column;
			}
			& > *{
				@media (max-width:767px){
					width: 100%;
					margin-left: 0px !important;
				}
				.float-end{
					@media (max-width:767px){
						float: none !important;
						margin-bottom: 0px !important;
						margin-top: 10px;
					}
				}
			}
		}
		.preview{
			&-list{
			}
			&-item{
				p{
					font-size: 13px;
				}
				&-action{
					i{
						font-size: 14px;
					}
				}
			}
			&-subject{
				font-size: 14px;
			}
		}
	}
}
.preview{
	&-list{
	}
	&-item{
		width: 100%;
		&-content{
			flex: 1 0; 
			width: 0;
		}
		&-action{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding-left: 15px;
			& > *{
				margin-right: 10px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	
}
.navbar .navbar-menu-wrapper .navbar-nav.navbar-nav-right{
	&.navbar{
		&-nav{
			&-items{
				&-right{
					flex: 0 0 auto;
					width: auto;
					margin-left: 0;
				}
			}
		}
	}
}
.navbar .navbar-menu-wrapper .navbar-toggler{
	&:not(.navbar-toggler-right){
		display: block;
		padding: 0;
		width: auto;
		height: auto;
		@media ( max-width: 991px){
			order: 1;
		}
	}
	.sidebar-icon-only &{
		color: #000000;
	}

}